import React from 'react'
import { Container } from 'react-bootstrap'
import ImageModule from '../../modules/image-render'
import InstantValuationForm from '../forms/instant-valuation-form'
import FormFields from "../../../static/forms/instant_valuation.json"
import "./InstantValuation.scss"
const InstantValuation = ({ PageData }) => {
    const image = PageData?.banner[0]?.image
  var imagename = "page.banner_section_banner_image.landing_banner_image"

  let processedImages = JSON.stringify({})
  if (PageData?.imagetransforms) {
    processedImages =
      PageData?.imagetransforms.banner_section_banner_image_Transforms
  }
  return (
    <div className="instant-valuation-wrapper">
    <div className="instant-valuation-section">
      <ImageModule
        ImageSrc={image}
        altText={`${
          image?.alternativeText
            ? image?.alternativeText
            : PageData.banner_title
            ? PageData.banner_title
            : PageData.title
        } banner`}
        imagetransforms={processedImages}
        renderer="bgImg"
        imagename={imagename}
        strapi_id={PageData?.strapi_id}
        classNames="img-fluid banner-img"
      />
      <Container>
       

            <InstantValuationForm
              fields={FormFields}
              PageData={PageData}
              formtagclassname="instant-valuation-form"
            />
      
      </Container>

    </div>
  </div>
  )
}

export default InstantValuation