import { graphql } from "gatsby"
import React from "react"
import BookValuation from "../components/BookValuation/BookValuation"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import GeneralEnquiry from "../components/GeneralEnquiry/GeneralEnquiry"
import HomeValuation from "../components/HomeValuation/HomeValuation"
import InstantValuation from "../components/InstantValuation/InstantValuation"
import Layout from "../components/layout"
import SendCvComponent from "../components/SendCvComponent/SendCvComponent"
import SEO from "../components/seo"

const FormTemplate = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <div className="form-template-wrapper">
      <Layout popularSearch={PageData?.select_popular_search?.title}>
        <SEO
          title={
            PageData?.seo?.metaTitle
              ? PageData?.seo?.metaTitle
              : PageData?.title
          }
          description={
            PageData?.seo?.metaDescription
              ? PageData?.seo?.metaDescription
              : PageData?.title
          }
        />
        <BreadcrumbModule
          choose_menu={PageData.choose_menu}
          pagename={PageData.title}
        />
        {PageData.add_page_modules?.map((item, i) => {
          return (
            <div>
              {item?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
                item?.select_module === "contact_form" && (
                  <GeneralEnquiry PageData={PageData} />
                )}
              {item?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
                item?.select_module === "career_form" && (
                  <SendCvComponent PageData={PageData} />
                )}
              {item?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
                item?.select_module === "instant_valuation" && (
                  <InstantValuation PageData={PageData} />
                )}
              {item?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
                item?.select_module === "book_valuation" && (
                  <BookValuation PageData={PageData} />
                )}
                {item?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
                item?.select_module === "homevisit_valuation" && (
                  <HomeValuation PageData={PageData} />
                )}
            </div>
          )
        })}
      </Layout>
    </div>
  )
}

export default FormTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          __typename
          select_module
          title
        }
      }
    }
  }
`
