import React from "react"
import { Container } from "react-bootstrap"
import ImageModule from "../../modules/image-render"
import DefaultForm from "../forms/default-form-layout"
import "./BookValuation.scss"
import FormFields from "../../../static/forms/bookvaluation_form.json"
import ContentModule from "../../modules/content-render";
const BookValuation = ({ PageData }) => {
  const image = PageData?.banner[0]?.image
  var imagename = "page.banner_section_banner_image.landing_banner_image"

  let processedImages = JSON.stringify({})
  if (PageData?.imagetransforms) {
    processedImages =
      PageData?.imagetransforms.banner_section_banner_image_Transforms
  }
  return (
    <div className="book-valuation-wrapper">
      <div className="book-valuation-section">
        <ImageModule
          ImageSrc={image}
          altText={`${
            image?.alternativeText
              ? image?.alternativeText
              : PageData.banner_title
              ? PageData.banner_title
              : PageData.title
          } banner`}
          imagetransforms={processedImages}
          renderer="bgImg"
          imagename={imagename}
          strapi_id={PageData?.strapi_id}
          classNames="img-fluid banner-img"
        />
        <Container>
          <div className="valuation-form-wrapper">
            <div className="valuation-section">
              <div className="text-content">
              <h1>{PageData?.title}</h1>
                <div className="contact-text">
                {PageData?.content?.data?.content && <ContentModule Content={PageData?.content?.data?.content} />}
                </div>
              </div>

              <DefaultForm
                fields={FormFields}
                formtagclassname="valuation-form"
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default BookValuation
